.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden; 
  background-color: #EBECF0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}




body{
overflow: hidden;
margin: 0;
padding: 0;
}


h1 {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 4%;

}

hr {
  border-top: 2px solid #4B4C4D;
  margin: 4% 0;

}

/* Customize the main input fields (hex and rgba) */
#rbgcp-input {
  border: 2px solid #007bff;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  /* background-color: white !important; */
  color: #333;
}

/* Styling for gradient control inputs */
.rbgcp-control-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 12px;
}

/* Customize the text buttons */
.rbgcp-control-btn {
  color: #007bff;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
}

/* Style for selected buttons */
.rbgcp-control-btn-selected {
  color: white;
  background-color: #007bff;
  border-radius: 4px;
  padding: 6px 12px;
}

/* Dropdown for selecting color modes */
.rbgcp-color-model-dropdown {
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
}

/* Wrapper around the control buttons */
.rbgcp-control-btn-wrapper {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 6px;
}

/* Styling icon buttons */
.rbgcp-control-icon-btn {
  color: #007bff;
  cursor: pointer;
}


.three-canvas-container {
  display: flex;
  width: 100%; 
  height: auto;
  overflow: hidden; /* Prevents any overflow from the canvas container */
  min-height: 100%;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  background-color: #252627;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 15px; 
  width: 20%; 
  height: 5%;
  left: 51%; 
  z-index: 5;
  border-radius: 10px; 
  /* border: 1px solid red; */
}

.header-container.shifted {
  left: 41%; 
}

.header-container button {
  width: 10%;
  height: 60%;
  background-color: #383838;
  border-radius: 5px;
}

.header-container button:hover {
  background-color: #8729CF;
}

.header-container img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.visual-assistant-button {
  height: 60%;
  width: 60%;
}

.visual-assistant-button img {
  height: 100%;
  width: 100%;
}

.image-ai-toggle-container {
  height: 100%;
  width: 100%;
}

.image-ai-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #383838;
    border-radius: 5px;
    color: white;
    width: 100%;
    height: 100%;
}

.image-ai-toggle:hover {
  background-color: #8729CF;
}

.image-ai-background {
  position: fixed;
  z-index: 2;
  padding: 10px;
  background-color: #252627;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 20%;
  padding: 20px;
  background-color: #252627;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  border-radius: 30px; /* Rounded top-right and bottom-right corners */
  height: 96%;
}

#image-ai-title {
  width: 100%;
  height: 35px;
  padding: 0px 70px;  
  margin: 0;
}


.image-ai-content {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 12px;
  width: 100%;
  overflow: hidden;
}

.image-ai-content button {
  height: 35px;
}

.image-ai-content button:hover {
  background-color: #8729CF;
}

.image-ai-content input {
  height: 35px;
}


.ai-text-response {
 max-height: 200px;
 overflow-y: auto;
 background-color: #383838;
 height: 200px;
 border-radius: 5px;
 padding: 5px;
}

.ai-text-response::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Scrollbar track */
.ai-text-response::-webkit-scrollbar-track {
  background: #252627; /* Background color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Scrollbar thumb */
.ai-text-response::-webkit-scrollbar-thumb {
  background-color: #8729CF; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #8729CF; /* Adds a border around the thumb */
}

/* Scrollbar thumb on hover */
.ai-text-response::-webkit-scrollbar-thumb:hover {
  background-color: #b84dd4; /* Slightly lighter color on hover */
}

/* Optional: Customize the scrollbar buttons */
.ai-text-response::-webkit-scrollbar-button {
  display: none; /* Hide the up and down buttons if you don't want them */
}

.three-canvas-area-background {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EBECF0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.three-canvas-area {
  display: flex;
  flex-shrink: 0;
  left: 11%;
  position: relative;
  transform-origin: center;
  z-index: 1;
  overflow: visible;
  align-items: center;
  justify-content: center;

}

.three-canvas-area:focus {
  outline: none;
}

.three-canvas-area.shifted {
  left: 1%;
}

.zoom-slider-container {
  padding: 10px 0;
  background-color: #252627;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  bottom: 15px; 
  width: 35%; 
  height: 3%;
  left: 43.5%; 
  z-index: 5;
  border-radius: 10px; 
  transition: transform 0.3s ease;
}

.zoom-slider-container.shifted {
  left: 33.5%; 
}

.zoom-slider {
  width: 70%; /* Adjust the width of the slider */
  height: 75%;
  accent-color: #8729CF;
}


.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4%;
}

#clear-button {
  margin-top: 4%;
}


.buttons-container button {
  width: 100%;
  background-color: #383838;
  color: white; /* White text */
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.buttons-container button:hover {
  background-color: #8729CF;
}

#shutterstock-button {
  background-color: #FF2917;
  background-image: url('/public/shutterstock-logo-black-and-white.png');
  background-position: center;
  background-size: 135px;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #FF2917;
  cursor: pointer;
  height: 4%;
  margin-top: 4%;
}

#shutterstock-button:hover {
  background-color: #E21E0D;
}





.buttons-container-shapes {
  display: flex;
  justify-content: space-around; /* Align buttons in a row */
  align-items: center;

  
}

.buttons-container-shapes button {
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 25%;
  height: 100% ;  
  background-color: #383838;
  cursor: pointer;
  border-radius: 5px;
  color: #cbcbcb;
}

.buttons-container-shapes svg {
  width: 100%;
  height: 100%;
  fill: currentColor; /* Allows you to easily style the SVG with text color */
}

.buttons-container-shapes button:hover{
  color: #8729CF !important; /* Example: Change color on hover */
}

.position-customization {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  height: 10%;
  box-sizing: border-box; /* Ensures padding doesn’t overflow the container */
  gap: 15%;
}


.move-buttons-shapes {
  display: flex;  /* Align the horizontal and vertical groups inline */
  gap: 3.5%;  
  width: 100%;
  height: 42.5%;
  /* border: 1px solid green; */
  align-items: center;  /* Vertically center inputs with labels */
  flex: 1;
}

.move-buttons-horizontal-shapes,
.move-buttons-vertical-shapes {
  display: flex;
  /* border: 1px solid blue; */
  flex: 1;
  width: 40%;
  height: 100%;
} 


.move-buttons-horizontal-shapes button,
.move-buttons-vertical-shapes button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3%;  /* Set explicit width */
  height: 100%; /* Set the height to match the input fields */
  background-color: #383838;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.move-buttons-horizontal-shapes button:hover,
.move-buttons-vertical-shapes button:hover {
  background-color: #8729CF;
} 

.move-buttons-horizontal-shapes button img,
.move-buttons-vertical-shapes button img {
  width: 100%;  /* Make the image fill the button */
  height: 100%; /* Ensure the image takes up full height */
  object-fit: contain;  /* Preserve the aspect ratio of the image */
  border-radius: 5px;  /* Optional: match the button's rounded corners */
}


#left-align {
  border-radius: 5px 0px 0px 5px;
}

#center-align {
  border-radius: none;
}

#right-align {
  border-radius: 0px 5px 5px 0px;
}

.rotation-customization-container-shapes {
  display: flex;
  align-items: center;  /* Vertically center all items */
  /* border: 1px solid green; */
  height: 42.5%;
  width: 100%;
  gap: 3.5%;
  flex: 1;
}

.rotation-customization-input-shapes {
  display: flex;
  flex: 1;
  align-items: center;  /* Vertically center the label and input */
  height: 100%;
  width: 40%;
  /* border: 1px solid blue; */
}

.rotation-customization-buttons-shapes {
  display: flex;
  flex: 1;
  align-items: center;  /* Vertically align the label and input */
  height: 100%;
  width: 40%;
  /* border: 1px solid blue; */
}

.rotation-customization-input-shapes label {
  display: flex;
  align-items: center;  /* Align the label text to the left */
  color: white;
  background-color: #383838;
  border-radius: 5px 0px 0px 5px;
  padding-left: 10px;
  height: 100%;
  width: 25%;

}

.rotation-customization-input-shapes input {
  width: 75%;  /* Adjust the width of the input */
  height: 35px;  /* Match the button height */
  border-radius: 0px 5px 5px 0px;
  background-color: #383838;
  border: none;
  color: white;
  padding: 5px;
  height: 100%;

}

.rotation-customization-input-shapes input:focus{
  outline: none;
}

/* Remove spinners in Chrome, Safari, Edge, and Opera */
.rotation-customization-input-shapes input[type="number"]::-webkit-outer-spin-button,
.rotation-customization-input-shapes input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
.rotation-customization-input-shapes input[type="number"] {
  -moz-appearance: textfield;
}


.rotation-customization-input-shapes img {
  width: 24px;
  height: 24px;
}

.rotation-customization-buttons-shapes button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3%;  /* Set button width */
  height: 100%;  /* Set button height */
  background-color: #383838;
  border: none;
  cursor: pointer;
  padding: 8px;
  height: 100%;

}

.rotation-customization-buttons-shapes button:hover {
  background-color: #8729CF;
}

.rotation-customization-buttons-shapes button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.size-input-container-shapes {
  display: flex;  /* Use flexbox to align items inline */
  align-items: center;  /* Vertically center inputs with labels */
  gap: 3.5%;
  /* border: 1px solid red; */
  height: 35px;

}

.size-input-container-shapes-w,
.size-input-container-shapes-h
{
  /* border: 1px solid blue; */
  display: flex;
  width: 40%;
  height: 100%;
  flex: 3;
  border-radius: 5px;
  align-items: center;  /* Vertically center content */

}

.size-input-container-shapes button {
  display: flex;
  justify-content: center;  /* Center button content */
  align-items: center;  /* Vertically center button */
  width: 10%;  /* Let the button size dynamically */
  height: 100%;  /* Match the height of the parent */
  flex: 1;  /* Don't let this grow, keep minimal width */
  background-color: #383838;
  border-radius: 5px;
  /* border: 1px solid blue; */
  /* padding: 5px; */
}

.size-input-container-shapes-w label,
.size-input-container-shapes-h label {
  display: flex;
  align-items: center;
  width: 30%;
  background-color: #383838;
  color: white;
  padding-left: 5px;
  border-radius: 5px 0px 0px 5px;
  height: 100%;
}

.size-input-container-shapes-w input,
.size-input-container-shapes-h input {
  width: 50%;
  background-color: #383838;
  color: white;
  height: 100%;
  
}


.size-input-container-shapes-w span,
.size-input-container-shapes-h span {
  display: flex;
  align-items: center;
  width: 20%;
  background-color: #383838;
  color: white;
  border-radius: 0px 5px 5px 0px;
  height: 100%;
}



.size-input-container-shapes button:hover {
  background-color: #8729CF;
}



.size-input-container-shapes button img {
  flex: 1;
  width: 55%;  /* Make the image fill the button */
  height: 55%; /* Ensure the image takes up full height */
  object-fit: contain;  /* Preserve the aspect ratio of the image */
  border-radius: 5px;  /* Optional: match the button's rounded corners */
}

.size-input-container-shapes input:focus{
  outline: none;
}

/* Remove spinners in Chrome, Safari, Edge, and Opera */
.size-input-container-shapes input[type="number"]::-webkit-outer-spin-button,
.size-input-container-shapes input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
.size-input-container-shapes input[type="number"] {
  -moz-appearance: textfield;
}

.navbar {
  width: 4%;
  top: 16%;
  bottom: 16%;
  left: 2%; 
  background-color: #18191B;
  transition: width 0.3s ease;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 25px 0;
  position: absolute;
  border-radius: 20px 20px 20px 20px; /* Rounded top-left and bottom-left corners */
  justify-content: center; /* Center buttons vertically */
}

.navbar .button-container {
  display: flex;
  width: 100%;
  height: 10%; 
  margin-bottom: 30px;
  flex-shrink: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.navbar .toggle-button {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px 50px;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.navbar .toggle-button:hover {
  opacity: 1;
}


.navbar .toggle-button.active {
  background-color: #252627; /* This is the color when a button is active */
  opacity: 1; /* Ensure full opacity when active */
}


.toggle-button-1 {
  background-image: url('/public/Canvas-Icon-Text.png');

}

.toggle-button-2 {
  background-image: url('/public/Image-Icon-Text.png');

}

.toggle-button-3 {
  background-image: url('/public/Render-Icon-Text.png');

}

.toggle-button-4 {
  background-image: url('/public/Text-Icon-White.png');

}

.toggle-button-5 {
  background-image: url('/public/Shape-Icon-Text.png');
}

.toggle-button-6 {
  background-image: url('/public/Layers-Icon-Text.png');
}



/* Sidebar Menu */
.three-canvas-sidebar,
.three-canvas-sidebar-2,
.three-canvas-sidebar-3,
.three-canvas-sidebar-4,
.three-canvas-sidebar-5,
.three-canvas-sidebar-6 {
  width: 20%; 
  padding: 20px 15px 20px 55px;
  margin-left: -10px;
  background-color: #252627;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  position: absolute;
  left: 4.5%;
  top: 2%;
  bottom: 2%; /* 50px from the bottom */
  border-radius: 30px; /* Rounded top-right and bottom-right corners */

}

.sidebar-content-wrapper {
  height: 100%; /* Takes up the entire height of the sidebar */
  display: flex;
  flex-direction: column; /* Align children vertically */
}



.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px; 
}

.logo-container img {
  width: 100%;
  max-width: 200px;
}

.shape-square,
.shape-circle,
.shape-triangle {
  position: absolute;
  width: 100%;
  height: 100%;
}


.shape-customization-container {
  display: flex;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}




.layers-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
}

.layer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #343637;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  /* border: 1px solid red; */

}

.layer-item:hover {
  background-color: #4B4C4D;
}

.layer-item-name {
 /* border: 1px solid green; */
 width: 75%;
}

.layer-item-name input {
  color: black;
  width: 100%;
}

.layer-item-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid purple; */
  width: 20%;
}

.layer-item-buttons-container button {
  height: 100%;
  width: 45%;
  /* border: 1px solid blue; */
}





#scrollable-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 10px; /* Space between the buttons */
  padding: 20px;
  max-height: 300px; /* Set the height to make it scrollable */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Ensure the container takes full width */
  border-radius: 5px;
  background-color: #383838;
}

#scrollable-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Scrollbar track */
#scrollable-container::-webkit-scrollbar-track {
  background: #252627; /* Background color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Scrollbar thumb */
#scrollable-container::-webkit-scrollbar-thumb {
  background-color: #8729CF; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #8729CF; /* Adds a border around the thumb */
}

/* Scrollbar thumb on hover */
#scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #b84dd4; /* Slightly lighter color on hover */
}

/* Optional: Customize the scrollbar buttons */
.scrollable-container::-webkit-scrollbar-button {
  display: none; /* Hide the up and down buttons if you don't want them */
}


#image-button {
  background-color: #f0f0f0; /* Fallback background color */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px; /* Fixed height for each button */
  width: 120px; /* Fixed width for each button */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-size: cover; /* Ensure the background image covers the entire button */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background from repeating */
  overflow: hidden; /* Ensure nothing overflows the button */
  padding: 0; /* Remove any padding */
}





.moveable-control.moveable-origin {
  display: none !important;
}

.moveable-control {
  border-radius: 50%; /* Make the handles circular */
  width: 10px;
  height: 10px;
  background-color: white !important;
  border: 2px solid #8729CF !important;
  /* display: none !important; */
}

.moveable-control:hover {
  background-color: #8729CF !important;
}


.moveable-line{
   border: 1px solid #8729CF !important;   
}

.moveable-container {
  will-change: transform, width, height;
}

.exposure-customization input[type=number] {
  margin: 0px 0px 7px 10px;
  background-color: #383838;
  border-radius: 5px;
  text-align: center;
  width: 45px;
}

.exposure-customization input[type=number]:focus{
  outline: none;
}

/* Remove spinners in Chrome, Safari, Edge, and Opera */
.exposure-customization input[type=number]::-webkit-outer-spin-button,
.exposure-customization input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
.exposure-customization input[type=number] {
  -moz-appearance: textfield;
}

.font-customization-container {
  /* height: 25%; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.font-size-font-weight {
  display: flex;
  gap: 10px; /* Adds space between input and select */
  margin-bottom: 4%;
  /* border: 1px solid red; */
}

.font-size-font-weight input,
.font-size-font-weight select {
  flex: 1; /* Makes both input and select take equal space */
  height: 35px;
  background-color: #383838;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
}


.font-size-font-weight select:focus {
  outline: none;
  border-color: #8729CF;      /* Border color on focus */
}

.font-size-font-weight option {
  background-color: #4B4C4D;  /* Background color of options */
  color: white;               /* Text color */
}

.font-size-font-weight option:checked {
  background-color: #8729CF;  /* Color of selected option */
}

.font-size-font-weight input:focus{
  outline: none;
}

/* Remove spinners in Chrome, Safari, Edge, and Opera */
.font-size-font-weight input::-webkit-outer-spin-button,
.font-size-font-weight input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
.font-size-font-weight input {
  -moz-appearance: textfield;
}

.letter-spacing-container {
  display: flex;
  justify-content: center;
  gap: 11px; /* Gap between the two letter-spacing divs */
  margin-bottom: 4%;
  width: 100%;
  /* border: 1px solid blue; */
}

.h-letter-spacing,
.v-letter-spacing {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  width: 100%; /* Both sections take full width */
}

.letter-spacing-container label {
  display: flex;
  align-items: center; /* Align image and input field horizontally */
  background-color: #383838;
  border-radius: 5px 0px  0px 5px;
  padding: 5px;
  width: 25%; /* Label takes 25% of the parent width */
  height: 35px;

}

.letter-spacing-container label img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.letter-spacing-container input {
  width: 75%; /* Input takes 75% of the parent width */
  padding: 5px;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box; /* Ensure padding doesn't affect input size */
  color: white;
  background-color: #383838;
  height: 35px;
}

.letter-spacing-container input:focus {
  outline: none;
}

/* Remove spinners in Chrome, Safari, Edge, and Opera */
.letter-spacing-container input[type="number"]::-webkit-outer-spin-button,
.letter-spacing-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
.letter-spacing-container input[type="number"] {
  -moz-appearance: textfield;
}

.letter-align-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px; 
  width: 100%;
  /* border: 1px solid green; */
  height: 35px;
  /* flex: 1; */
}

.letter-align-buttons-container button {
  height: 100%;
  background-color: #383838;
  border-radius: 5px;
  padding: 6px; 
  width: 50%;
  align-items: center;
}


.letter-align-buttons-container button:hover {
  background-color: #8729CF;
}

.letter-align-buttons-container button img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-items: center;
}




.modal-content {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  margin-top: 4%;
  width: 100%; /* Ensure modal content takes full width */
}

.export-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%; /* Make sure inputs use full width of container */
}


.modal-content input {
  flex: 2;
  height: 35px;
  background-color: #383838;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculation */
}


.modal-content select {
  flex: 1;
  height: 35px;
  background-color: #383838;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculation */
}

.modal-content select:focus,
.modal-content input:focus {
  outline: none;
  border-color: #8729CF;
}

.export-checkbox {
  display: flex;
  align-items: center;
  margin: 4% 0;
  justify-content: flex-start;
  /* border: 1px solid green; */
  background-color: #383838;
  border-radius: 5px;
  color: white;
  height: 35px;
  box-sizing: border-box; /* Ensures padding is within the width */
}

.export-checkbox input {
  display: flex;
  align-items: center;
  flex: 1;
  accent-color: #8729CF;
  height: 50%;
  width: 50%;
  /* border: 1px solid blue; */
}

.export-checkbox label {
  display: flex;
  align-items: center;
  flex: 7;
  color: white;
  margin-left: 5px; /* Adjust space between checkbox and label */
  /* border: 1px solid blue; */
  height: 100%;
}

.export-button {
  display: flex;
  align-items: center;
  height: 35px;
  /* border: 1px solid red; */
}

.export-button button {
  width: 100%;
  background-color: #383838;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.export-button button:hover {
  background-color: #8729CF;
}



.color-picker-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 7px;
    background-color: #383838;
    border-radius: 5px;
    color: white;
    width: 100%;
}

.color-picker-background {
    position: fixed;
    z-index: 2;
    padding: 10px;
    background-color: #252627;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 270px;
   
}

.color-picker-drag {
  height: 20px;
}


.font-picker-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px;
  background-color: #383838;
  border-radius: 5px;
  color: white;
  width: 100%;
  margin-bottom: 4%;
}

.font-picker-drag {
  height: 20px;
}

.font-picker-scrollable-list {
  max-height: 215px;
}



.font-picker-scrollable-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Scrollbar track */
.font-picker-scrollable-list::-webkit-scrollbar-track {
  background: #252627; /* Background color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Scrollbar thumb */
.font-picker-scrollable-list::-webkit-scrollbar-thumb {
  background-color: #8729CF; /* Color of the scrollbar thumb */
  border-radius: 10px ; /* Rounded corners for the thumb */
  border: 2px solid #8729CF; /* Adds a border around the thumb */
}

/* Scrollbar thumb on hover */
.font-picker-scrollable-list::-webkit-scrollbar-thumb:hover {
  background-color: #b84dd4; /* Slightly lighter color on hover */
}

/* Optional: Customize the scrollbar buttons */
.font-picker-scrollable-list::-webkit-scrollbar-button {
  display: none; /* Hide the up and down buttons if you don't want them */
}


.exposure-sliders {
  accent-color: #8729CF;
  color: white;
}


@media screen  and (min-width: 1024px) and (max-width: 1439px) {
  /* .three-canvas-area-background {
    background-color: blue;
  } */

  .App {
    font-size: 12px;
  }

  h1 {
    font-size: 0.8rem;
  }
  
  h2 {
    font-size: 0.8rem;

 
  
  }
  /* Sidebar Menu */
.three-canvas-sidebar,
.three-canvas-sidebar-2,
.three-canvas-sidebar-3,
.three-canvas-sidebar-4,
.three-canvas-sidebar-5,
.three-canvas-sidebar-6 {
  width: 20%; 
  padding: 20px 10px 20px 40px;

}


.navbar .toggle-button {
  background-size: 40px 40px;

}


.size-input-container-shapes button img {
  width: 40%;  /* Make the image fill the button */
  height: 40%; /* Ensure the image takes up full height */
}

.move-buttons-horizontal-shapes button,
.move-buttons-vertical-shapes button {
  padding: 6px;
}

.rotation-customization-buttons-shapes button {
  padding: 6px;

}


.letter-spacing-container label img {
  width: 100%;
  height: 75%;
  border-radius: 5px;
}

.letter-align-buttons-container button {
  padding: 4px; 
}

.logo-container {

  margin-top: 15px; 
  margin-bottom: 0;
}

.color-picker-background {
  position: fixed;
  z-index: 2;
  padding: 10px;
  background-color: #252627;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 200px;
}
.color-picker-drag {
  height: 10px;
}

.font-picker-drag {
  height: 10px;
}

.font-picker-scrollable-list {
  max-height: 160px;
}

#image-ai-title {
  padding: 0px 20px;  

}

}


@media screen  and (min-width: 1440px) and (max-width: 1919px) {
  /* .three-canvas-area-background {
    background-color: red;
  } */

  .App {
    font-size: 13px;
  }

  h1 {
    font-size: 0.9rem;
  }
  
  h2 {
    font-size: 0.9rem;

 
  
  }

  
.navbar .toggle-button {
  background-size: 45px 45px;

}


.size-input-container-shapes button img {
  width: 45%;  /* Make the image fill the button */
  height: 45%; /* Ensure the image takes up full height */
}

.move-buttons-horizontal-shapes button,
.move-buttons-vertical-shapes button {
  padding: 7px;
}

.rotation-customization-buttons-shapes button {
  padding: 7px;

}

.letter-spacing-container label img {
  width: 100%;
  height: 90%;
  border-radius: 5px;
}

.letter-align-buttons-container button {
  padding: 6px; 
}

.logo-container {

  margin-top: 15px; 
  margin-bottom: 0;
}

.color-picker-background {
  position: fixed;
  z-index: 2;
  padding: 10px;
  background-color: #252627;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 200px;
 
}

.color-picker-drag {
  height: 10px;
}

.font-picker-drag {
  height: 10px;
}

.font-picker-scrollable-list {
  max-height: 160px;
}

#image-ai-title {
  padding: 0px 50px;  

}

}



@media screen  and (min-width: 1920px) {
  /* .three-canvas-area-background {
    background-color: purple;
  } */

}