.background-image {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: black;
  margin: auto;
  
}

.login-content img {
  width: 30%;
  height: 40%;
}